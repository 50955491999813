/**
 * Please, do not change this URL. There should actually only be one instance of the NOE
 * website, and this should be at this URL. It helps also crediting our work ! Thanks :)
 */
export const WEBSITE_URL =
  process.env.NODE_ENV === "production"
    ? "https://get.noe-app.io"
    : process.env.REACT_APP_WEBSITE_URL;

/**
 * Some constant URLs
 */
export const DONATE_URL =
  "https://noe-app.notion.site/Indications-pour-les-dons-libres-et-conscients-au-projet-NO-0ad0938dc1e946f594148922ed3f85d0?pvs=4";
export const DISCORD_INVITE_LINK = "https://discord.gg/hdrJnFqmXJ";
export const GITLAB_URL = "https://gitlab.com/noe-app/noe";
