import {Comparer, createEntityAdapter, IdSelector} from "@reduxjs/toolkit";
import dayjs from "@shared/services/dayjs";

/**
 * Generic reducers and adapters
 */

export const createCustomEntityAdapter = <T>(adapterOptions: {
  selectId?: IdSelector<T>;
  sortComparer?: false | Comparer<T>;
}) => {
  const adapter = createEntityAdapter(adapterOptions);
  return {
    ...adapter,
    reducers: {
      addToList: adapter.addOne,
      updateInList: adapter.setOne,
      removeFromList: adapter.removeOne,
      initContext: (state: any) => {
        state.init.status = "pending";
      },
      resetContext: (state: any, action: {payload: {removeProject: boolean}}) => {
        // If we want to clean everything, we have to reset all the stuff
        if (action.payload?.removeProject) {
          delete state.init.project;
          adapter.removeAll(state);
        }

        // Otherwise,
        delete state.init.updatedAt;
      },
      initList: (
        state: any,
        action: {payload: {list: Array<T & {deletedAt?: string; _id: string}>; project: string}}
      ) => {
        // Only make changes if the list is not empty
        if (action.payload.list?.length > 0) {
          // Separate objects that are new and objects that have been deleted
          const newAndUpdatedList = action.payload.list.filter((el) => !el.deletedAt);
          const deletedList = action.payload.list.filter((el) => el.deletedAt);

          // If we are reloading a list that has already been loaded, just do some modifs, else, just replace all (in case we change project)
          if (state.init.project === action.payload.project) {
            adapter.setMany(state, newAndUpdatedList);
            adapter.removeMany(
              state,
              deletedList.map((el) => el._id)
            );
          } else {
            adapter.setAll(state, newAndUpdatedList);
          }
        }

        state.init.status = "loaded";
        state.init.updatedAt = dayjs().subtract(1, "second").unix() * 1000;
        state.init.project = action.payload.project;
      },
      changeEditing: (state: any, action: {payload: Partial<T>}) => {
        state.editing = {...state.editing, ...action.payload};
      },
      setEditing: (state: any, action: {payload: T}) => {
        state.editing = action.payload;
      },
    },
  };
};
