import {useEffect} from "react";
import {useMatch} from "react-router";
import {useNavigate} from "react-router-dom";

export function Redirect({
  from,
  to,
  else: fallback,
  paramsNames,
}: {
  from?: string;
  to: string;
  else?: string;
  paramsNames?: Array<string>;
}) {
  let navigate = useNavigate();
  let matches = useMatch(from || "/");

  if (from && paramsNames) {
    if (matches !== null) {
      for (const paramName of paramsNames) {
        to = to.replaceAll(":" + paramName, matches.params[paramName] as string);
      }
    } else {
      to = fallback as string;
    }
  }

  useEffect(() => {
    navigate(to, {replace: true});
  });

  return null;
}
