import {
  App,
  message as globalAntdMessage,
  Modal as globalAntdModal,
  notification as globalAntdNotification,
} from "antd";
import {useAppProps} from "antd/es/app/context";
import {useLayoutEffect} from "react";

// For now, some functions still manage message and notification popups, while being outside any React scope.
// That means that using Antd messages here is possible using `import {message, notification} from "antd"` but we can't have the
// custom Antd configuration and theming because we are out of scope.
// So the solution is to store a link to the instanciated theme function in a global variable. We initialize this global variable thanks
// to a hook that is called in a React context.
export let AppMethods: useAppProps = {
  message: globalAntdMessage,
  notification: globalAntdNotification,
  modal: globalAntdModal as any as useAppProps["modal"],
}; // First, initialize it with the default one

export const useThemedAppMethods = () => {
  const appMethods = App.useApp();
  useLayoutEffect(() => {
    // But as soon as we can, change it to the instantiated ones
    AppMethods.message = appMethods.message;
    AppMethods.notification = appMethods.notification;
    AppMethods.modal = appMethods.modal;
  }, [appMethods]);
};
