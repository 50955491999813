import {displayNotification} from "@shared/utils/displayNotification";
import {App, Button} from "antd";
import {t} from "i18next";
import {useEffect} from "react";

export const useBrowserUpdate = () => {
  const {message, notification} = App.useApp();

  useEffect(() => {
    setTimeout(
      () =>
        import("browser-update").then(({default: browserUpdate}) => {
          try {
            browserUpdate({
              required: {e: -4, f: -3, o: -3, s: -1, c: -3},
              notify_esr: true,
              // debug: true,
              reminderClosed: 24 * 30,
              nomessage: true,
              onshow: (infos) => {
                displayNotification("warning", "browserOutdatedNotification", {
                  message: t("common:browserUpdate.message"),
                  description: t(
                    "common:browserUpdate.description",
                    // @ts-ignore infos.browser actually does exist
                    {t: infos.browser.t}
                  ),
                  btn: (
                    <div className="containerH buttons-container">
                      <Button
                        type="primary"
                        onClick={() => {
                          notification.destroy("browserOutdatedNotification");
                          window
                            .open("https://browser-update.org/update-browser.html", "_blank")
                            ?.focus();
                        }}>
                        {t("common:browserUpdate.button")}
                      </Button>
                      <Button
                        onClick={() => {
                          infos.onclose(infos);
                          infos.setCookie(infos.reminderClosed);
                          notification.destroy("browserOutdatedNotification");
                          setTimeout(
                            () =>
                              message.info(
                                t("common:browserUpdate.notifyAgainInXDays", {
                                  days: Math.round(infos.reminderClosed / 24),
                                })
                              ),
                            1000
                          );
                        }}>
                        {t("common:ignore")}
                      </Button>
                    </div>
                  ),
                });
              },
            });
          } catch (e) {
            /* ignore */
          }
        }),
      7000
    );
  }, [message, notification]);
};
