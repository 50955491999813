import {captureException} from "@sentry/react";
import dayjsBase, {ConfigType, Dayjs} from "dayjs";
import "dayjs/locale/fr";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import minMax from "dayjs/plugin/minMax";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjsBase.extend(utc);
dayjsBase.extend(minMax);
dayjsBase.extend(LocalizedFormat);
dayjsBase.extend(localeData);
dayjsBase.extend(customParseFormat);
dayjsBase.extend(timezone);

const PROJECT_TIMEZONE = "Europe/Paris";
dayjsBase.tz.setDefault(PROJECT_TIMEZONE);

/**
 * The default dayjs instance has been modified to always display things in the same timezone,
 * no matter where the user is located on the globe.
 *
 * This is useful if you are in France, and you plan an event that taks place in Asia, while some
 * American participants try to registr to your event. That way, people will all get the same time no matter what.
 */
const dayjs = (...args: Parameters<typeof dayjsBase>) => dayjsBase(...args).tz(PROJECT_TIMEZONE);

/**
 * We export the dayjsBase which is the real, untouched, dayjs instance.
 *
 * It can be used for two purposes only:
 * - When we want to access functions that are only available on the base instance (like .utc(), .localeData(), .min(), .max(), etc.)
 * - When we wanna display the date and time in the user's own timezone (for example when the user wants to export data,
 *   we put the real user date in the export file name)
 */
export type {ConfigType, Dayjs};
export {dayjsBase, PROJECT_TIMEZONE};
export default dayjs;

/**
 * Guess the user's timezone with an additional fallback on UTC if the timezone couldn't be guessed.
 */
export const guessUserTimezone = () => {
  const guessedTimezone = dayjsBase.tz.guess();
  if (guessedTimezone === "Etc/Unknown") {
    const errorMessage = "User timezone could not be determined. Using 'UTC' instead.";
    console.log(errorMessage);
    captureException(errorMessage);
    return "UTC";
  } else {
    return guessedTimezone;
  }
};

console.debug(`User timezone: '${guessUserTimezone()}'`);
