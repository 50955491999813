import type {CustomFieldFilter} from "./filterSessionsByCustomFields";
import {getEndpointInSession} from "./getEndpointInSession";
import {getSessionSubscription} from "@utils/registrationsUtilities";

// Takes the custom field value for a particular key, and
// returns a list of all the values found in all the elements, no matter the original type of data.
const mergeAllCustomFieldsValues = (key: CustomFieldFilter["key"], element): Array<string> =>
  Array.isArray(element.customFields?.[key])
    ? element.customFields?.[key] || []
    : [element.customFields?.[key]];

// Takes all the custom fields value for a particular key, and
// returns a list of all the values found in all the elements.
const mergeAllCustomFieldsValuesMultiple = (
  key: CustomFieldFilter["key"],
  elements
): Array<string> => {
  return elements?.reduce(
    (allValues, element) => [...allValues, ...mergeAllCustomFieldsValues(key, element)],
    []
  );
};

export const oneToOneRelationEndpoints = ["categories", "activities", "teams", "sessions"];
export const oneToManyRelationEndpoints = ["stewards", "places"];

export const getAllCustomFieldValuesForEndpoint = (key, endpoint, session, registrations?) =>
  // One-one relationships
  oneToOneRelationEndpoints.includes(endpoint)
    ? mergeAllCustomFieldsValues(key, getEndpointInSession(endpoint, session))
    : // One-many relationships
    oneToManyRelationEndpoints.includes(endpoint)
    ? mergeAllCustomFieldsValuesMultiple(key, getEndpointInSession(endpoint, session))
    : // Registrations are a bit different
    endpoint === "registrations" && registrations
    ? mergeAllCustomFieldsValuesMultiple(
        key,
        registrations?.filter((r) => getSessionSubscription(r, session))
      )
    : [];
