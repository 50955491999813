import {useDispatch, useSelector} from "react-redux";
import {usersActions} from "@features/users";
import {components, StepType, TourProvider, useTour} from "@reactour/tour";
import {DependencyList, useEffect} from "react";
import {currentUserSelectors} from "@features/currentUser";
import {App} from "antd";
import {useTranslation} from "react-i18next";

export const clickOnTab = (tabKey: string) => {
  clickOnElement(`[data-node-key='${tabKey}']`);
};

export const clickOnElement = (selector: string) => {
  try {
    document.querySelector(selector).click();
  } catch (e) {
    console.log("failed to click on element in user tour.");
  }
};

export const selectTab = (tabKey: string, selector) => ({
  selector: selector || `[data-node-key='${tabKey}']`,
  resizeObservables: selector ? [selector] : [`[data-node-key='${tabKey}']`],
  mutationObservables: selector ? [selector] : [`[data-node-key='${tabKey}']`],
  action: () => clickOnTab(tabKey),
});

const toggleSidebar = () => {
  try {
    // Open the sidebar if it exists
    document.querySelector(".navbar-menu-button > *").click();
  } catch (e) {
    // do nothing if it fails
  }
};
export const inSidebar = (selector) => ({
  action: toggleSidebar,
  actionAfter: toggleSidebar,
  selector, // Resize if the sidebar is collapsed from the agenda mode
  resizeObservables: [selector],
});

export const useUserTour = (
  tourKey: string,
  steps: Array<StepType>,
  {
    delayBeforeShow = 500,
    shouldShowNow = () => true,
    deps = [],
  }?: {
    delayBeforeShow: number,
    shouldShowNow: (currentUser: any) => boolean,
    deps?: DependencyList,
  } = {}
) => {
  const {setSteps, setCurrentStep, setIsOpen, setMeta, isOpen} = useTour();

  const authenticatedUser = useSelector(currentUserSelectors.selectAuthenticatedUser);
  const shouldShowTour = !authenticatedUser.shownTours?.includes(tourKey);

  useEffect(() => {
    if (shouldShowTour && !isOpen && shouldShowNow(authenticatedUser)) {
      const timeoutId = setTimeout(() => {
        setMeta(tourKey);
        setCurrentStep(0);
        setSteps(steps);
        setIsOpen(true);
      }, delayBeforeShow);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowTour, setCurrentStep, setSteps, isOpen, ...deps]);
};

export const UserTourProvider = ({children}) => {
  const {message} = App.useApp();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const authenticatedUser = useSelector(currentUserSelectors.selectAuthenticatedUser);

  // On close tour, add the tour to the shown tours, so it is not shown again to the user
  const closeAndDontDisplayAnymore = ({currentStep, steps, setIsOpen, meta: tourKey}) => {
    setIsOpen(false);
    if (tourKey === "" || authenticatedUser?.shownTours?.includes(tourKey)) return;
    dispatch(
      usersActions.persist(
        {
          _id: authenticatedUser._id,
          shownTours: [...(authenticatedUser.shownTours || []), tourKey],
        },
        false
      )
    );
    if (currentStep < steps.length - 1) {
      message.info(
        `Vous n\'avez pas terminé le tutoriel. Pour le revoir, allez dans votre profil et cliquez sur "${t(
          "users:schema.resetUserTours.buttonTitle"
        )}".`,
        6
      );
    }
  };

  return (
    <TourProvider
      scrollSmooth
      steps={[]}
      onClickClose={closeAndDontDisplayAnymore}
      keyboardHandler={(e, clickProps) => {
        // Keep default behavior for arrows
        if (e.key === "ArrowRight") {
          clickProps.setCurrentStep(
            Math.min(clickProps.currentStep + 1, clickProps.steps.length - 1)
          );
        }
        if (e.key === "ArrowLeft") {
          clickProps.setCurrentStep(Math.max(clickProps.currentStep - 1, 0));
        }

        // On escape, don't display anymore
        if (e.key === "Escape") {
          closeAndDontDisplayAnymore(clickProps);
        }
      }}
      onClickMask={closeAndDontDisplayAnymore}
      components={{
        Navigation: (props) => {
          const {currentStep, steps} = props;
          return (
            steps.length > 1 && (
              <components.Navigation
                {...props}
                nextButton={
                  currentStep === steps.length - 1
                    ? () => <div style={{width: 16}}></div>
                    : props.nextButton
                }
                prevButton={
                  currentStep === 0 ? () => <div style={{width: 16}}></div> : props.prevButton
                }
              />
            )
          );
        },
      }}
      styles={{
        popover: (base) => ({
          ...base,
          "--reactour-accent": "var(--noe-accent)",
          background: "var(--colorBgElevated)",
          borderRadius: 10,
        }),
        close: (base) => ({...base, right: 10, top: 10, transform: "scale(1.4)"}),
        arrow: (base) => ({...base, transform: "scale(1.6)"}),
        dot: (base) => ({...base, transform: "scale(1.2)"}),
        badge: (base) => ({...base, background: "var(--noe-gradient)"}),
        maskWrapper: (base) => ({...base, color: "var(--text)", opacity: 0.15}),
        maskArea: (base) => ({...base, rx: 14}),
      }}>
      {children}
    </TourProvider>
  );
};
