import {normalize} from "@shared/utils/stringUtilities";
import {Select, SelectProps} from "antd";
import React from "react";
import {FormItem, FormItemProps} from "./FormItem";

export const SelectComponent = (props: SelectProps) => (
  <Select
    filterOption={(inputValue, option) =>
      normalize(option?.label).indexOf(normalize(inputValue)) !== -1
    }
    // prevent selection with Enter to trigger full page validation
    onKeyDown={(event) => event.key === "Enter" && event.stopPropagation()}
    {...props}
  />
);

export const SelectInput = (props: FormItemProps<SelectProps>) => (
  <FormItem {...props}>
    <SelectComponent bordered={false} />
  </FormItem>
);
