import {ReactNode} from "react";
import reactStringReplace from "react-string-replace";

export const truncate = (str: string, n: number) =>
  str.replace(new RegExp("(.{" + n + "})..+"), "$1...");

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const isValidObjectId = (id: string) => !!/[0-9a-z]{24}/.exec(id);

export const normalize = (str?: Object | null, caseSensitive = false) => {
  const normalized =
    str
      ?.toString() // Must be done if the string is actually a number
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") || "";
  return caseSensitive ? normalized : normalized.toLowerCase();
};

const DEFAULT_DELIMITERS = {start: "{{", end: "}}"};

/**
 * Replaces some strings in a text
 * @param text the initial text
 * @param valuesToReplace a key/value hash of values to match and replace
 * @param delimiter by default, will be {start: "{{", end: "}}"}. If false, no delimiter at all.
 * @returns {string}
 */
export const replaceInText = (
  text: string,
  valuesToReplace: Record<string, string>,
  delimiter: false | {start?: string; end?: string} = DEFAULT_DELIMITERS
) => {
  if (!text || text === "") return;

  for (const [match, replace] of Object.entries(valuesToReplace)) {
    text = text.replaceAll(
      delimiter
        ? `${delimiter.start || DEFAULT_DELIMITERS.start}${match}${
            delimiter.end || DEFAULT_DELIMITERS.end
          }`
        : match,
      replace
    );
  }
  return text;
};

/**
 * Replaces some strings in a text, but allows also some JSX in the replacements
 * @param text the initial text
 * @param valuesToReplace a key/value hash of values to match and replace
 * @param delimiter by default, will be {start: "{{", end: "}}"}
 * @returns {ReactNode}
 */
export const replaceInTextWithJSX = (
  text: string,
  valuesToReplace: Record<string, ReactNode>,
  delimiter: {start?: string; end?: string} = DEFAULT_DELIMITERS
) => {
  if (!text || text === "") return null;

  return reactStringReplace(
    text,
    new RegExp(
      `${delimiter.start || DEFAULT_DELIMITERS.start}(.*?)${
        delimiter.end || DEFAULT_DELIMITERS.end
      }`,
      "g"
    ),
    (match) => {
      return valuesToReplace[match];
    }
  ).filter((value) => value !== "");
};

export const camelCaseify = (str: string) => {
  // Remove unsafe symbols (non-alphanumeric and non-underscore characters)
  const safeString = normalize(str).replace(/[^a-zA-Z0-9 ]/g, "");

  // CamelCaseify the string
  return safeString
    .split(/\s+/)
    .map((word, index) =>
      index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join("");
};

export const generateRandomId = (length = 5) =>
  (Math.random() + 1).toString(36).substring(2, length + 2);
