import MatomoTrackerClass from "@jonkoops/matomo-tracker";

const isMatomoEnabled =
  process.env.REACT_APP_MATOMO_CLOUD_URL && process.env.REACT_APP_MATOMO_CONTAINER_JS_URL;

export const MatomoTracker = isMatomoEnabled
  ? new MatomoTrackerClass({
      urlBase: "/m/a/t/o/m/o",
      siteId: 1, // Will be overridden when script loads, anyways
      trackerUrl: "/m/a/t/o/m/o/-/p/h/p",
      srcUrl: "/m/a/t/o/m/o/-/j/s",
    })
  : null;

export const changeMatomoUser = (user?: {email: string; _id: string}) => {
  if (!MatomoTracker) return;
  if (user?._id) {
    // https://developer.matomo.org/guides/tracking-javascript-guide#when-user-is-logged-in-set-the-user-id
    MatomoTracker.pushInstruction("setUserId", user._id);
    MatomoTracker.trackPageView();
  } else {
    // See https://developer.matomo.org/guides/tracking-javascript-guide#when-user-logs-out-reset-user-id
    MatomoTracker.pushInstruction("resetUserId");
    MatomoTracker.pushInstruction("appendToTrackingUrl", "new_visit=1");
    MatomoTracker.trackPageView();
    MatomoTracker.pushInstruction("appendToTrackingUrl", "");
  }
};
