import {fetchWithMessages} from "./fetchWithMessages";
import {dayjsBase} from "@shared/services/dayjs";

export const AUTO_RELOAD_SECONDS = 10;

export const loadListFromBackend = async (
  endpoint,
  projectId,
  initData,
  initContextAction,
  initEntityAction,
  forceLoad = false,
  showLoading = true,
  notFoundAction
) => {
  // Reload if we are not already in a pending reload AND if we either:
  // - have no updatedAt date
  // - have an old updatedAt date
  // - have a different projectId than the current one
  if (
    forceLoad ||
    (initData.status !== "pending" &&
      (!initData.updatedAt ||
        dayjsBase().isAfter(dayjsBase(initData.updatedAt).add(AUTO_RELOAD_SECONDS, "second")) ||
        initData.project !== projectId))
  ) {
    initContextAction && initContextAction();

    try {
      const data = await fetchWithMessages(
        projectId ? `projects/${projectId}/${endpoint}` : endpoint,
        {
          method: "GET",
          queryParams:
            initData.updatedAt && initData.project === projectId // Only do incremental if projectId is the same and there is a date
              ? {updatedAfter: initData.updatedAt, showDeletedElements: true}
              : undefined,
        },
        undefined,
        undefined,
        !initData.updatedAt && showLoading
      );

      initEntityAction(data);
    } catch (e) {
      if (notFoundAction) {
        notFoundAction();
      } else {
        throw e;
      }
    }
  }
};
