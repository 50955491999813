import {ACTIVATING_OFFLINE_MODE, OFFLINE_MODE} from "../offlineModeUtilities";
import {t} from "i18next";
import {lastWebsocketConnectionId} from "./webSocket";
import {URLS} from "@app/configuration";
import {displayServerNotAvailableErrorModal} from "../displayServerNotAvailableErrorModal";
import {AppMethods} from "@shared/hooks/useThemedAppMethods";

const buildUrlSearchParams = (params) =>
  new URLSearchParams(
    Object.fromEntries(
      Object.entries(params).filter(([key, val]) => val !== undefined && val !== null && val !== "")
    )
  );

const MESSAGE_KEY = "fetchWithMessagesKey";

const DEFAULT_ERROR_MESSAGES = {
  403: {
    type: "error",
    message: (mess) => {
      // If it's a field conflict, return the proper formatted string
      const matchingSameFieldValueError = /objectCannotHaveSameFieldValue: (.*)/g.exec(mess);
      if (matchingSameFieldValueError) {
        const fieldNames = matchingSameFieldValueError[1].split(",");
        return t("common:defaultErrorMessages.error", {
          context: "objectCannotHaveSameFieldValue",
          fieldNames,
        });
      }
      // Else, return the backend message.
      return mess;
    },
  },
  404: () => t("common:defaultErrorMessages.error", {context: "404"}),
  409: {
    type: "error",
    message: () => t("common:defaultErrorMessages.error", {context: "409"}),
    duration: 10,
  },
  500: () => t("common:defaultErrorMessages.error", {context: "500"}),
  503: () => t("common:defaultErrorMessages.error", {context: "503"}),
};

const loadingMessagesCounter = {
  val: 0,
  inc: () => (loadingMessagesCounter.val += 1),
  dec: () => {
    if (loadingMessagesCounter.val > 0) loadingMessagesCounter.val -= 1;
    return loadingMessagesCounter.val > 0;
  },
};

export const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

type StatusMessage = String | Function<String | Object> | Boolean;
type StatusMessageObject =
  | StatusMessage
  | {message: StatusMessage, type: "success" | "info" | "warning" | "error" | "loading"};

// Display a message. I the input is an arrow function, execute it,
// otherwise if it's a string just put it inside the message as it is
const displayMessage = (statusMessage: StatusMessageObject, data, defaultType = "error") => {
  // If the status Message is a simple string or a simple function, build it
  const builtStatusMessage =
    typeof statusMessage === "object" ? statusMessage : {type: defaultType, message: statusMessage};

  if (builtStatusMessage.message === true) {
    // If equal to true, just take the raw data given as argument
    builtStatusMessage.message = data;
  } else {
    // If the message is an arrow function, run it, else if it's a string, take it as it is
    builtStatusMessage.message =
      typeof builtStatusMessage.message == "function"
        ? builtStatusMessage.message(data)
        : builtStatusMessage.message;
  }

  loadingMessagesCounter.dec();

  return AppMethods.message[builtStatusMessage.type]({
    content: builtStatusMessage.message,
    key: MESSAGE_KEY,
    duration: builtStatusMessage.duration, // If undefined, leave as is
  });
};

export const displayLoadingMessage = (content) => {
  loadingMessagesCounter.inc();
  return AppMethods.message.loading({
    content: content || t("common:loading"),
    key: MESSAGE_KEY,
    duration: 0,
  });
};

export const killLoadingMessage = (timeoutVar) => {
  loadingMessagesCounter.dec();
  if (loadingMessagesCounter.val === 0) {
    clearTimeout(timeoutVar);
    AppMethods.message.destroy(MESSAGE_KEY);
  }
};

/**
 * Helper for all the fetches we do to the backends
 * @param endpoint for example `categories/${payload}`
 * @param noResponseData set this argument to true to tell that no data will be returned from server.
 * @param noAuthNeeded if you don't need to set the Authorization field in the request headers.
 * @param isBlob the response is binary data, not JSON.
 * @param args the arguments for the fetch method
 * @param args.body body of the request.
 * @param args.queryParams queryParams that you can pass, that are automatically parsed as JSON to string and to URL.
 * @param args.method the method of the request.
 * @param statusMessages status messages for each HTTP status. status messages can be of various forms:
 *  {
 *   200: "Success!", // Simple strings
 *   201: (dataFromServer) => "Success " + dataFromServer, // Simple arrow functions
 *   403: {type: "warning", message: "A simple warning"}, // Simple strings with custom type
 *   404: {type: "warning", message: (messageFromServer) => "An evolved warning " + messageFromServer}, // Simple arrow functions with custom types
 *   304: true, // This will display automatically the text from server if set to true, as it was received
 *   305: {type: "info", message: true} // Same here with custom type
 *  }
 * @param defaultErrorMessage it's a string to display on an error that you didn't manage yourself.
 * @param showLoading show a loading message during the request.
 * @param showLoadingDelay delay the beginning of the loading message (for very short requests, so it appears only if the request is very long).
 * @param throwError throw an error if fetch fails, instead of just rejecting the Promise.
 *
 *  If not set, the default error messages will be used. Set this argument to false to disable default messages from appearing.
 * @return {Promise<void>} returns the data object when the request is successful, the full response object if not successful
 */
export const fetchWithMessages = async (
  endpoint: String,
  {noResponseData = false, isBlob = false, noAuthNeeded = false, ...args},
  statusMessages: Object<StatusMessageObject> = {},
  defaultErrorMessage: String | Boolean,
  showLoading: Boolean = false,
  showLoadingDelay = 0,
  throwError = false
) => {
  if (OFFLINE_MODE && !ACTIVATING_OFFLINE_MODE) return Promise.reject();

  if (args.queryParams) endpoint = `${endpoint}?${buildUrlSearchParams(args.queryParams)}`;

  const showLoadingTimeOut = showLoading && setTimeout(displayLoadingMessage, showLoadingDelay);

  // Transform the body to JSON string before sending
  if (args.body) args.body = JSON.stringify(args.body);

  // Build headers with defaults.
  args.headers = {
    ...DEFAULT_HEADERS,
    "x-current-pathname": window.location.pathname, // For logging purpose in the backend: be able to see from where the request was triggered
    ...args.headers,
  };

  // Add authentication only if needed
  if (!noAuthNeeded) args.headers.Authorization = `JWT ${localStorage.getItem("token")}`;

  // Add websocket connection id if present
  if (lastWebsocketConnectionId) args.headers["x-websocket-connection"] = lastWebsocketConnectionId;

  // Impersonation
  if (localStorage.getItem("x-connected-as-user")?.length > 0) {
    args.headers["x-connected-as-user"] = localStorage.getItem("x-connected-as-user");
  }

  try {
    const response = await fetch(`${URLS.API}/${endpoint}`, args);

    if (response.status.toString()[0] === "2") {
      // If the status is a success status (ie. 200 or any 2XX status), do this:
      const data = noResponseData ? true : isBlob ? await response.blob() : await response.json(); // Only get the data if there is some
      const successMessage = statusMessages[response.status] || statusMessages[200]; // fallback on 200 if nothing found
      if (successMessage) {
        displayMessage(successMessage, data, "success");
      } else {
        showLoading && killLoadingMessage(showLoadingTimeOut);
      }
      return data;
    } else {
      // If the server responds with an error, do this:
      const messageFromServer = await response.text(); // Will return "" if no text returned
      const statusMessage = statusMessages[response.status];

      if (statusMessage) {
        // If there is a message for this status number, display it
        displayMessage(statusMessage, messageFromServer);
      } else if (statusMessage === null) {
        // Don't display anything, fail silently if error message is null
      } else if (defaultErrorMessage !== false) {
        // Otherwise display the default message, or display nothing if it is equal to false
        const fallbackDefaultMessage =
          defaultErrorMessage ||
          DEFAULT_ERROR_MESSAGES[response.status] ||
          DEFAULT_ERROR_MESSAGES.fallback;
        displayMessage(fallbackDefaultMessage, messageFromServer);
      } else {
        showLoading && killLoadingMessage(showLoadingTimeOut);
      }
      //TODO les returns doivent être homogenes. une data est retourné ci dessus et une promesse rejecté ici
      return Promise.reject(response);
    }
  } catch (e) {
    e.message === t("common:failedToFetch") && displayServerNotAvailableErrorModal();
    showLoading && killLoadingMessage(showLoadingTimeOut);
    if (throwError) {
      throw e;
    } else
      AppMethods.message.error({
        key: "contentCouldNotBeLoaded",
        content: t("common:defaultErrorMessages.contentCouldNotBeLoaded"),
      });
  }
};
